import classnames from "classnames";

import UserPositionMarker from "components/Icons/user_position_marker.svg";

import styles from "./MeMarker.module.css";
type Props = { lat: number; lng: number; shouldShowMeMarker: boolean };

export const MeMarker = ({ lat, lng, shouldShowMeMarker = true }: Props) => (
  <div
    className={classnames(styles.meMarker, {
      hidden: !shouldShowMeMarker,
    })}
    data-map-coordinates={`lat: ${lat}, lng: ${lng}`}
    data-testid="map-marker"
  >
    <UserPositionMarker height="32" width="32" />
  </div>
);

export default MeMarker;
