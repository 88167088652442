import { useContext, useState } from "react";
import dynamic from "next/dynamic";

import getDispensariesV3 from "api/requests/consumerApi/getDispensariesV3";
import ConfigContext from "context/ConfigContext";
import LocationContext from "context/LocationContext";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import useOnComponentInView from "hooks/useOnComponentInView";
import { getUserHistoryItems } from "utils/userHistory";

const RecentlyViewedCarousel = dynamic(
  () => import("./RecentlyViewedCarousel"),
);

const RecentlyViewedCarouselWithData = () => {
  const { retailType, storeRoute } = useContext(ConfigContext);

  const [recentlyViewedStores, setRecentlyViewedStores] = useState<
    DispensaryV3[]
  >([]);

  const { finderLocation } = useContext(LocationContext);

  const [ref] = useOnComponentInView(
    async () => {
      const { coordinates } = finderLocation;

      const storedDispensaries = await getUserHistoryItems("dispensary", {}, 5);
      const recentlyViewedIds = storedDispensaries?.length
        ? storedDispensaries.map((entry) => entry.resourceId).filter((id) => id)
        : [];

      if (recentlyViewedIds.length) {
        const { stores } = await getDispensariesV3({
          disableGeoFilter: true, // don't filter by geo, only use for calc distances
          flags: [retailType],
          ids: recentlyViewedIds,
          lat: coordinates?.lat,
          lon: coordinates?.lon,
          merchUnitName: "NONE", // don't split out platinums
        });

        if (stores.length) {
          setRecentlyViewedStores(
            stores.sort(
              (a, b) =>
                recentlyViewedIds.indexOf(a.id) -
                recentlyViewedIds.indexOf(b.id),
            ),
          );
        }
      }
    },
    { triggerOnce: true },
  );

  return (
    <div ref={ref}>
      {recentlyViewedStores.length > 0 && (
        <RecentlyViewedCarousel
          storeRoute={storeRoute}
          stores={recentlyViewedStores}
        />
      )}
    </div>
  );
};

export default RecentlyViewedCarouselWithData;
