import { useContext } from "react";
import { NextParsedUrlQuery } from "next/dist/server/request-meta";
import Head from "next/head";
import { useRouter } from "next/router";

import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import { CountryCode } from "custom-types/CountryCode";
import useDomain from "hooks/useDomain";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { shouldNoIndexLocationPages } from "utils/browserUtils";
import { getFinderFilterForSeo } from "utils/finder/directusRequestUtils";
import { getPageMetaData } from "utils/seoMetaDataStrings";

const shouldNoIndexFilters = (query: NextParsedUrlQuery) =>
  (!!query?.filter && getFinderFilterForSeo(query.filter) === "") ||
  (!!query?.filter && query?.filter?.includes("delivery")) ||
  (Array.isArray(query?.filter) && query?.filter.length > 1);

const FinderHead: React.FC = () => {
  const { asPath, query } = useRouter();

  const domain = useDomain();
  const countryCode: CountryCode = useDomainCountryCode();

  const { finderLocation } = useContext(FinderResultsContext);
  const { FAQData, retailType, isLocationPage, organization } =
    useContext(ConfigContext);

  const showTopLangAlternates = (asPath.match(/\//g) || []).length <= 1;

  const { page = 1 } = query;

  const locationOverrides = FAQData.data
    ? {
        canonical: FAQData?.data?.canonical,
        meta_description: FAQData?.data?.meta_description,
        meta_title: FAQData?.data?.meta_title,
      }
    : undefined;

  const metadata = getPageMetaData({
    countryCode,
    location: isLocationPage ? finderLocation : undefined,
    locationOverrides,
    organization,
    query,
    retailType,
  });

  const pageQueryParam =
    Number(page) > 1
      ? metadata.canonical.includes("?")
        ? `&page=${page}`
        : `?page=${page}`
      : "";

  const isUS = countryCode === "US" || finderLocation?.countryCode === "US";
  const shouldShowAlternates = !isLocationPage && isUS;

  let noindexPage = false;

  if (
    shouldNoIndexLocationPages({
      domainCountryCode: countryCode,
      isLocationPage,
      locationCountryCode: finderLocation?.countryCode || countryCode,
      stateCode: finderLocation?.stateCode,
    }) ||
    // no-index all filters except black_owned, open_now, or medical and no-index if there are multiple filters
    shouldNoIndexFilters(query) ||
    // no-index if query params are location or zip
    asPath.includes("&location") ||
    asPath.includes("?location") ||
    Object.hasOwn(query, "zip")
  ) {
    noindexPage = true;
  }

  return (
    <Head>
      {noindexPage && <meta name="robots" content="noindex" key="noindex" />}
      <title>{`${metadata.title}${
        Number(page) > 1 ? ` | Page ${page}` : ""
      }`}</title>
      <link
        rel="canonical"
        href={`${domain}${metadata.canonical}${pageQueryParam}`}
      />
      {shouldShowAlternates && (
        <>
          <link
            rel="alternate"
            hrefLang={showTopLangAlternates ? "en-us" : undefined}
            href={`https://www.leafly.com${metadata.canonical}`}
          />
          <link
            rel="alternate"
            hrefLang={showTopLangAlternates ? "en-ca" : undefined}
            href={`https://www.leafly.ca${metadata.canonical}`}
          />
        </>
      )}
      <meta
        name="description"
        content={`${metadata.description}${
          Number(page) > 1 ? ` Page ${page}.` : ""
        }`}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=yes"
      />
      <link rel="preconnect" href="https://maps.googleapis.com" />
    </Head>
  );
};

export default FinderHead;
