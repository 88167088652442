import { ReactNode, useContext } from "react";
import classnames from "classnames";
import Link from "next/link";

import { filters as testIds } from "constants/finder/testIds";
import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import { useEventTracker } from "hooks/useEventTracker";
import { createFiltersObject } from "utils/filterUtils";

import ClearIcon from "components/Icons/x.svg";

const ActiveFilters: React.FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className, children }) => {
  const { activeFilters, handleToggleFilter, availableFilters } =
    useContext(FinderResultsContext);

  const filterLabels = createFiltersObject(availableFilters);

  const { retailType } = useContext(ConfigContext);

  const { publishEvent } = useEventTracker();

  return (
    <div
      className={classnames(
        "active-filters flex items-center -mx-xs",
        className,
      )}
    >
      {children}

      {retailType === "cbd-store" && (
        <Link
          href="/dispensaries"
          data-testid={`${testIds.remove}-cbd}`}
          onClick={() =>
            publishEvent({
              action: "click",
              category: "finder filter",
              filter: "hemp / cbd stores",
              label: "cbd & delta stores",
            })
          }
          className="active-filters__button button text-xs w-auto flex flex-row rounded py-xs px-md m-xs icon--centered bg-green-20 border-green-40 border text-default whitespace-nowrap body-scroll-lock-ignore"
        >
          <span data-testid={`${testIds.removeLabel}-filter`}>Hemp / CBD</span>
          <ClearIcon height="12" width="12" className="ml-xs" />
        </Link>
      )}

      {activeFilters
        .filter((f: string) => !["pickup", "delivery"].includes(f))
        .map((filter: string) => {
          const label =
            filterLabels[filter] ||
            filter.charAt(0).toUpperCase() + filter.slice(1).replace(/_/, " ");
          return (
            label && (
              <button
                data-testid={`${testIds.remove}-${filter}`}
                key={filter}
                onClick={() => handleToggleFilter(filter)}
                className="active-filters__button text-xs w-auto flex flex-row rounded py-xs px-md m-xs icon--centered bg-green-20
                border-green-40 border text-default whitespace-nowrap body-scroll-lock-ignore"
                style={{ flex: "0 0 auto" }}
              >
                <span data-testid={`${testIds.removeLabel}-${filter}`}>
                  {label}
                </span>
                <ClearIcon height="12" width="12" className="ml-xs" />
              </button>
            )
          );
        })}
    </div>
  );
};

export default ActiveFilters;
