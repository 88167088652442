import { CSSProperties, forwardRef, MouseEvent, ReactNode } from "react";
import classnames from "classnames";

type Props = {
  isActive?: boolean;
  testId?: string;
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  style?: CSSProperties;
};

const FilterPillButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      isActive,
      testId,
      children,
      onClick = () => {},
      className,
      ...props
    }: Props,
    ref,
  ) => (
    <button
      onClick={onClick}
      className={classnames(
        "text-xs w-auto py-xs px-md m-xs text-center text-green font-bold rounded content-center bg-white border border-solid",
        {
          "border-deep-green text-default bg-tertiary": isActive,
          "border-light-grey": !isActive,
        },
        className,
      )}
      data-testid={testId}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  ),
);

export default FilterPillButton;
