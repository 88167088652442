import { useContext } from "react";
import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { filters as filterIds } from "constants/finder/testIds";
import FinderResultsContext from "context/FinderResultsContext";
import { useEventTracker } from "hooks/useEventTracker";
import useUserFulfillmentPreferences from "hooks/useUserFulfillmentPreference";
import { setQueryParams } from "utils/nextRouterUtils";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

const FiltersAsTabs: React.FC = () => {
  const {
    query: { filter: queryFilter },
    asPath,
  } = useRouter();
  const { path } = parseNextRouterAsPath(asPath);
  const { publishEvent } = useEventTracker();

  const { setUserFulfillmentPreference } = useUserFulfillmentPreferences();
  const { activeFilters, setActiveFilters } = useContext(FinderResultsContext);

  const shouldBeLink = !activeFilters.length;
  const handleClick = (e: React.MouseEvent<HTMLElement>, filter: string) => {
    if (!shouldBeLink) {
      e.preventDefault();

      setQueryParams(
        {
          filter: filter !== "all" ? [...activeFilters, filter] : activeFilters,
        },
        false,
        ["page"],
      );
    }

    setActiveFilters(
      filter !== "all" ? [...activeFilters, filter] : [...activeFilters],
    );

    if (filter !== "all") {
      setUserFulfillmentPreference(filter);
    }

    publishEvent({
      action: "click",
      category: "finder filter",
      label: filter,
    });
  };

  const checkActive = (filter: string) => queryFilter?.includes(filter);
  const tabs = [
    {
      isActive: !checkActive("pickup") && !checkActive("delivery"),
      label: "All",
    },
    {
      isActive: checkActive("pickup"),
      label: "Pickup",
    },
    {
      isActive: checkActive("delivery"),
      label: "Delivery",
    },
  ];

  return (
    <div className="flex flex-row" id="filters-as-tabs">
      {tabs.map((tab) => {
        const lowercaseLabel = tab.label.toLowerCase();
        const sharedProps = {
          className: classnames(
            "cursor-pointer rounded-full mr-md px-md text-xs font-bold py-xs leading-[20px]",
            {
              "bg-default text-white cursor-default": tab.isActive,
              "text-green border-none": !tab.isActive,
            },
          ),
          "data-testid":
            filterIds[`${lowercaseLabel}Btn` as keyof typeof filterIds],
          key: lowercaseLabel,
          onClick: (e: React.MouseEvent<HTMLElement>) =>
            handleClick(e, lowercaseLabel),
          tabIndex: 0,
        };

        return shouldBeLink ? (
          <Link
            {...sharedProps}
            href={
              tab.label === "All" ? path : `${path}?filter=${lowercaseLabel}`
            }
          >
            {tab.label}
          </Link>
        ) : (
          <button {...sharedProps}>{tab.label}</button>
        );
      })}
    </div>
  );
};

export default FiltersAsTabs;
