import { useContext } from "react";

import FinderResultsContext from "context/FinderResultsContext";
import MapContext from "context/MapContext";
import { setQueryParams } from "utils/nextRouterUtils";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
};

const SearchButton = ({ children, isLoading }: Props) => {
  const { mapCenter, zoomLevel } = useContext(MapContext);

  const { searchThisArea } = useContext(FinderResultsContext);

  const handleClick = () => {
    if (!isLoading) {
      setQueryParams(
        {
          // no more `lng`, use `lon` for app "longitude" references.
          // `lng` is only used for google maps module/api purposes
          lat: mapCenter?.lat.toFixed(7),
          lon: mapCenter?.lon.toFixed(7),
          page: 1,
          zoom: zoomLevel,
        },
        false,
        ["page"],
      );
      searchThisArea(1);
    }
  };

  return (
    <button
      className="search-button rounded-full h-9 leading-9 shadow-low bg-default text-white text-xs px-xl mt-lg pointer-events-auto"
      id={isLoading ? "search-loading" : "search-this-area"}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default SearchButton;
