import { useContext, useState } from "react";

import { Action, Category } from "constants/events";
import staticSortOptions from "constants/finder/sorts";
import FinderResultsContext from "context/FinderResultsContext";
import { AvailableSort } from "custom-types/AvailableFilters";
import useClickAwayListener from "hooks/useClickAwayListener";
import { useEventTracker } from "hooks/useEventTracker";

import SolidArrowIcon from "components/Icons/arrows/SolidArrowIcon";

const DEFAULT_SORT_LABEL = "Recommended";

const Sorts = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { sortBy, handleSetSortBy, availableSorts } =
    useContext(FinderResultsContext);

  const activeSortLabel =
    sortBy?.display ||
    (availableSorts?.length &&
      availableSorts.find((o) => o.param === sortBy?.param)?.display) ||
    DEFAULT_SORT_LABEL;

  const { publishEvent } = useEventTracker();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickAway = () => {
    setDropdownOpen(false);
  };

  const handleSortClick = (sortOption: AvailableSort) => {
    publishEvent({
      action: Action.click,
      category: Category.finderSort,
      label: sortOption?.display.toLowerCase(),
    });
    setDropdownOpen(false);
    handleSetSortBy(sortOption);
  };

  const wrapperRef = useClickAwayListener(handleClickAway, dropdownOpen);

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
    <div className="text-sm my-sm relative" ref={wrapperRef as any}>
      <button
        className="flex justify-end text-xs w-full items-center"
        onClick={toggleDropdown}
        data-testid="sort-dropdown"
      >
        <div>
          <span className="w-auto ml-xs">
            Sort by &nbsp;
            <span className="font-bold">{activeSortLabel}</span>
          </span>
        </div>
        <SolidArrowIcon height="18" width="18" />
      </button>
      {dropdownOpen && (
        <div
          className="absolute border border-light-grey items-start flex flex-col right-0 pt-none pr-xl pb-sm pl-xl z-50 bg-leafly-white"
          style={{ width: "164px" }}
        >
          {staticSortOptions
            .filter(
              (sortOption: AvailableSort) =>
                sortOption?.param !== sortBy?.param,
            )
            .map((sortOption: AvailableSort) => (
              <button
                className="font-bold text-left text-xs pl-xs w-full"
                key={sortOption?.param}
                onClick={() => handleSortClick(sortOption)}
                data-testid={`sort-option-${(sortOption?.display || "")
                  .toLowerCase()
                  .replace(/\s/g, "-")
                  .trim()}`}
              >
                {sortOption?.display}
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default Sorts;
