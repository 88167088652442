export const canadianProvinces = [
  { abbr: "AB", name: "Alberta" },
  { abbr: "BC", name: "British Columbia" },
  { abbr: "MB", name: "Manitoba" },
  { abbr: "NB", name: "New Brunswick" },
  { abbr: "NL", name: "Newfoundland and Labrador" },
  { abbr: "NT", name: "Northwest Territories" },
  { abbr: "NS", name: "Nova Scotia" },
  { abbr: "NU", name: "Nunavut" },
  { abbr: "ON", name: "Ontario" },
  { abbr: "PE", name: "Prince Edward Island" },
  { abbr: "QC", name: "Quebec" },
  { abbr: "SK", name: "Saskatchewan" },
  { abbr: "YT", name: "Yukon" },
];

export const americanStates = [
  { abbr: "AL", name: "Alabama" },
  { abbr: "AK", name: "Alaska" },
  { abbr: "AS", name: "American Samoa" },
  { abbr: "AZ", name: "Arizona" },
  { abbr: "AR", name: "Arkansas" },
  { abbr: "CA", name: "California" },
  { abbr: "CO", name: "Colorado" },
  { abbr: "CT", name: "Connecticut" },
  { abbr: "DE", name: "Delaware" },
  { abbr: "DC", name: "District of Columbia" },
  { abbr: "FL", name: "Florida" },
  { abbr: "GA", name: "Georgia" },
  { abbr: "GU", name: "Guam" },
  { abbr: "HI", name: "Hawaii" },
  { abbr: "ID", name: "Idaho" },
  { abbr: "IL", name: "Illinois" },
  { abbr: "IN", name: "Indiana" },
  { abbr: "IA", name: "Iowa" },
  { abbr: "KS", name: "Kansas" },
  { abbr: "KY", name: "Kentucky" },
  { abbr: "LA", name: "Louisiana" },
  { abbr: "ME", name: "Maine" },
  { abbr: "MD", name: "Maryland" },
  { abbr: "MA", name: "Massachusetts" },
  { abbr: "MI", name: "Michigan" },
  { abbr: "MN", name: "Minnesota" },
  { abbr: "MS", name: "Mississippi" },
  { abbr: "MO", name: "Missouri" },
  { abbr: "MT", name: "Montana" },
  { abbr: "NE", name: "Nebraska" },
  { abbr: "NV", name: "Nevada" },
  { abbr: "NH", name: "New Hampshire" },
  { abbr: "NJ", name: "New Jersey" },
  { abbr: "NM", name: "New Mexico" },
  { abbr: "NY", name: "New York" },
  { abbr: "NC", name: "North Carolina" },
  { abbr: "ND", name: "North Dakota" },
  { abbr: "MP", name: "Northern Mariana Islands" },
  { abbr: "OH", name: "Ohio" },
  { abbr: "OK", name: "Oklahoma" },
  { abbr: "OR", name: "Oregon" },
  { abbr: "PA", name: "Pennsylvania" },
  { abbr: "PR", name: "Puerto Rico" },
  { abbr: "RI", name: "Rhode Island" },
  { abbr: "SC", name: "South Carolina" },
  { abbr: "SD", name: "South Dakota" },
  { abbr: "TN", name: "Tennessee" },
  { abbr: "TX", name: "Texas" },
  { abbr: "VI", name: "U.S. Virgin Islands" },
  { abbr: "UT", name: "Utah" },
  { abbr: "VT", name: "Vermont" },
  { abbr: "VA", name: "Virginia" },
  { abbr: "WA", name: "Washington" },
  { abbr: "WV", name: "West Virginia" },
  { abbr: "WI", name: "Wisconsin" },
  { abbr: "WY", name: "Wyoming" },
];

export const allStatesAndProvinces = [...americanStates, ...canadianProvinces];

export const getStateOrProvinceLongName = (abbr = "") => {
  const stateObj = allStatesAndProvinces.find((s) => s.abbr === abbr);
  return stateObj?.name || "";
};

export const getStatesForCountry = (country = "") => {
  const lcCountry = country.toLowerCase();
  if (lcCountry === "ca" || country === "canada") {
    return canadianProvinces;
  }
  return americanStates;
};
