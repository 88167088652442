import { ConfigProvider } from "context/ConfigContext";
import { LocationProvider } from "context/LocationContext";
import { MapProvider } from "context/MapContext";
import { UIProvider } from "context/UIContext";
import {
  InitialConfigContext,
  InitialLocationContext,
  InitialMapContext,
} from "custom-types/FinderPage";

import { FinderResultsProvider } from "./FinderResultsContext";

const ContextProviders = ({
  configContext,
  mapContext,
  locationContext,
  finderMigrationFlag,
  children,
}: {
  configContext: InitialConfigContext;
  mapContext: InitialMapContext;
  locationContext: InitialLocationContext;
  finderMigrationFlag?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <ConfigProvider data={configContext}>
      <UIProvider>
        <MapProvider data={mapContext}>
          <LocationProvider data={locationContext}>
            <FinderResultsProvider finderMigrationFlag={finderMigrationFlag}>
              {children}
            </FinderResultsProvider>
          </LocationProvider>
        </MapProvider>
      </UIProvider>
    </ConfigProvider>
  );
};

export default ContextProviders;
