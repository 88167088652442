import { ReactNode, useContext } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import {
  HAS_DEALS,
  MEDICAL_FILTER,
  OPEN_NOW_FILTER,
  PRE_ORDER_FILTER,
  RECREATIONAL_FILTER,
} from "constants/finder/filters";
import { filters as testIds } from "constants/finder/testIds";
import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import { hasAvailableFilters, isFilterAvailable } from "utils/filterUtils";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

import Placeholder, { Rect } from "components/Placeholder";

import FilterPillButton from "../FilterPillButton";

const LinkButton: React.FC<{
  children: ReactNode;
  filter: string;
  onClick?: () => void;
  path: string;
  testId: string;
}> = ({ path, children, filter, testId, onClick = () => {} }) => {
  const { availableFilters, activeFilters, handleToggleFilter } =
    useContext(FinderResultsContext);
  const {
    query: { filter: queryFilter },
  } = useRouter();

  const shouldBeLink = !queryFilter;

  const handleClick = () => {
    handleToggleFilter(filter);
    onClick();
  };

  const sharedButtonProps = {
    className: classnames("body-scroll-lock-ignore", {
      hidden: !hasAvailableFilters(availableFilters),
    }),
    isActive: activeFilters.includes(filter),
    testId,
  };

  return shouldBeLink ? (
    <Link href={path} onClick={handleClick} className="flex-shrink-0">
      <FilterPillButton {...sharedButtonProps}>{children}</FilterPillButton>
    </Link>
  ) : (
    <FilterPillButton
      onClick={handleClick}
      {...sharedButtonProps}
      className="flex-shrink-0"
    >
      {children}
    </FilterPillButton>
  );
};

const SkelePill = () => (
  <Placeholder width="75" height="30" className="m-xs rounded">
    <Rect height="100%" width="100%" />
  </Placeholder>
);

const FilterPillButtons = () => {
  const { retailType } = useContext(ConfigContext);

  const {
    availableFilters,
    activeFilters,
    loadingStores,
    handleClearAllFilters,
  } = useContext(FinderResultsContext);
  const { asPath } = useRouter();
  const { path } = parseNextRouterAsPath(asPath);

  const openNowAvailable = isFilterAvailable(availableFilters, OPEN_NOW_FILTER);
  const preOrderAvailable = isFilterAvailable(
    availableFilters,
    PRE_ORDER_FILTER,
  );
  const medicalAvailable = isFilterAvailable(availableFilters, MEDICAL_FILTER);
  const recreationAvailable = isFilterAvailable(
    availableFilters,
    RECREATIONAL_FILTER,
  );
  const dealsAvailable = isFilterAvailable(availableFilters, HAS_DEALS);

  const hasActiveMedOrRecFilters =
    activeFilters.includes(MEDICAL_FILTER) ||
    activeFilters.includes(RECREATIONAL_FILTER);

  return loadingStores ? (
    <>
      <SkelePill />
      <SkelePill />
      <SkelePill />
      <SkelePill />
      <SkelePill />
    </>
  ) : retailType !== "clinic" ? (
    <>
      {recreationAvailable && !hasActiveMedOrRecFilters && (
        <LinkButton
          filter={RECREATIONAL_FILTER}
          path={`${path}?filter=${RECREATIONAL_FILTER}`}
          testId={testIds.recreationalBtn}
        >
          Recreational
        </LinkButton>
      )}
      {medicalAvailable && !hasActiveMedOrRecFilters && (
        <LinkButton
          filter={MEDICAL_FILTER}
          path={`${path}?filter=${MEDICAL_FILTER}`}
          testId={testIds.medicalBtn}
        >
          Medical
        </LinkButton>
      )}
      {openNowAvailable && !activeFilters.includes(OPEN_NOW_FILTER) && (
        <LinkButton
          filter={OPEN_NOW_FILTER}
          path={`${path}?filter=${OPEN_NOW_FILTER}`}
          testId={testIds.openNowBtn}
        >
          Open now
        </LinkButton>
      )}
      {preOrderAvailable && !activeFilters.includes(PRE_ORDER_FILTER) && (
        <LinkButton
          filter={PRE_ORDER_FILTER}
          path={`${path}?filter=${PRE_ORDER_FILTER}`}
          testId={testIds.preOrderBtn}
        >
          Pre-order
        </LinkButton>
      )}
      {retailType === "dispensary" && (
        <Link
          href="/cbd-stores"
          className="flex-shrink-0"
          onClick={() => {
            handleClearAllFilters();
            trackEvent("finder filter", "click", "cbd & delta stores");
          }}
        >
          <FilterPillButton
            testId="apply-filters-button-cbd"
            className="body-scroll-lock-ignore"
          >
            Hemp / CBD
          </FilterPillButton>
        </Link>
      )}
      {dealsAvailable && !activeFilters.includes(HAS_DEALS) && (
        <LinkButton
          filter={HAS_DEALS}
          path={`${path}?filter=${HAS_DEALS}`}
          testId={testIds.dealsBtn}
        >
          Deals
        </LinkButton>
      )}
    </>
  ) : null;
};

export default FilterPillButtons;
