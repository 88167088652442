import { FAQ } from "custom-types/Directus";
import { Location } from "custom-types/Location";
import { Organization } from "custom-types/Organization";
import { RetailType } from "custom-types/Store";

import { getFinderFilterForSeo } from "./finder/directusRequestUtils";
import { getLocationLabel, getUrlLocationPart } from "./locationUtils";
import { QueryParams } from "./nextRouterUtils";

const _getMetaLocation = (location?: Location) => {
  const city = location?.city;
  const state = location?.state;
  const stateCode = location?.stateCode ?? location?.state_code;

  const locationString = location
    ? `${getLocationLabel({
        city,
        state,
        stateCode,
      })} `
    : "";

  return {
    city,
    locationString,
    state,
    stateCode,
  };
};

export const getFilteredOverride = (filter: string) => {
  switch (filter) {
    case "black_owned":
      return "Black-owned marijuana dispensaries";
    case "open_now":
      return "Dispensaries open now";
    case "medical":
      return "Medical marijuana dispensaries";
    default:
      return "Recreational and Medical Weed Dispensaries";
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
const getFurtherDescription = (filter: any, locationString: string) => {
  let desc = "";
  if (filter === "medical" && locationString) {
    desc = "Order your favorite strains from dispensaries";
  } else {
    desc = "Search the nearest " + getFilteredOverride(filter).toLowerCase();
  }
  if (filter === "black_owned" && !locationString) {
    desc = "Order your favorite strains from dispensaries";
  }

  desc += " with Leafly, ";
  desc += locationString ? locationString.trim() : "the world";
  desc += "'s online destination for cannabis!";
  return desc;
};

export const dispensariesMetaData = (
  countryCode: string,
  location?: Location,
  query: QueryParams = {},
  locationOverrides: FAQ = {},
  organization: Organization = {},
) => {
  const { city, state, locationString } = _getMetaLocation(location);
  const includesDeliveryFilter = !!query?.filter?.includes("delivery");
  const includesSingleIndexedFilter =
    (query?.filter?.includes("black_owned") ||
      query?.filter?.includes("open_now") ||
      query?.filter?.includes("medical")) &&
    !Array.isArray(query.filter);

  const localizedString =
    countryCode !== "CA" ? "Recreational & Medical Marijuana" : "Marijuana";

  if (organization?.name) {
    return {
      canonical: `/dispensaries/locations/${organization.slug}`,
      countryCode,
      description: `See a list of all ${organization.name} dispensaries on Leafly. Explore menus, read reviews, and get directions. Find a ${organization.name} near you!`,
      title: `All ${organization.name} Dispensary Locations | Leafly`,
    };
  }

  if (includesDeliveryFilter) {
    return {
      canonical: `/dispensaries${state ? `/${getUrlLocationPart(state)}` : ""}${
        city ? `/${getUrlLocationPart(city)}` : ""
      }?filter=delivery`,
      countryCode,
      description: `Order weed for delivery, find reviews, and menus from the best ${localizedString.toLowerCase()} dispensaries ${
        location ? "in " + locationString.trim() : "near me"
      }. Explore online ordering and delivery options.`,
      title: `${locationString}Weed Delivery near me${
        countryCode !== "CA" ? " - Recreational & Medical" : ""
      } | Leafly`,
    };
  }

  if (includesSingleIndexedFilter) {
    return {
      canonical: `/dispensaries${state ? `/${getUrlLocationPart(state)}` : ""}${
        city ? `/${getUrlLocationPart(city)}` : ""
      }${getFinderFilterForSeo(query.filter)}`,
      countryCode,
      description: `Find${
        query.filter === "open_now" ? " marijuana" : ""
      } ${getFilteredOverride(query.filter as string).toLowerCase()} ${
        locationString ? "in" : "near"
      } ${location ? locationString.trim() : "you"}. ${getFurtherDescription(
        query.filter,
        locationString,
      )}`,
      title: `${getFilteredOverride(query.filter as string)} ${
        locationString ? "in" : "near"
      } ${location ? locationString.trim() : "me"} | Leafly`,
    };
  }

  return {
    canonical:
      locationOverrides?.canonical ??
      `/dispensaries${state ? `/${getUrlLocationPart(state)}` : ""}${
        city ? `/${getUrlLocationPart(city)}` : ""
      }`,
    countryCode,
    description:
      locationOverrides?.meta_description ??
      `Find reviews and menus from the best ${localizedString.toLowerCase()} dispensaries ${
        location ? `in ${locationString.trim()} near you` : "near me"
      }. Explore online ordering and pick-up options.`,
    title:
      locationOverrides?.meta_title ??
      `${locationString}${
        countryCode !== "CA"
          ? "Weed Dispensaries Near Me"
          : "Dispensaries and Weed Stores Near Me"
      } | Leafly`,
  };
};

export const cbdStoresMetaData = (
  locationOverrides: FAQ,
  location?: Location,
) => {
  const { city, state, locationString } = _getMetaLocation(location);
  return {
    canonical:
      locationOverrides?.canonical ??
      `/cbd-stores${state ? `/${getUrlLocationPart(state)}` : ""}${
        city ? `/${getUrlLocationPart(city)}` : ""
      }`,
    description:
      locationOverrides?.meta_description ??
      `Discover and explore CBD stores ${
        location ? `in ${locationString.trim()}` : "near me"
      }. Find CBD oil, gummies, tinctures, and more. Get directions and read reviews.`,
    title:
      locationOverrides?.meta_title ??
      `${locationString}CBD and Hemp Stores Near You from Leafly`,
  };
};

export const MMJDoctorsMetaData = (
  locationOverrides: FAQ,
  location?: Location,
) => {
  const { city, state, locationString } = _getMetaLocation(location);
  return {
    canonical:
      locationOverrides?.canonical ??
      `/medical-marijuana-doctors${
        state ? `/${getUrlLocationPart(state)}` : ""
      }${city ? `/${getUrlLocationPart(city)}` : ""}`,
    description:
      locationOverrides?.meta_description ??
      `Find a medical weed doctor ${
        location ? `in ${locationString.trim()}` : "near me"
      } who specializes in evaluating patients seeking medical marijuana cards using Leafly. Learn more, book an appointment, and get directions.`,
    title:
      locationOverrides?.meta_title ?? locationString
        ? `Find medical marijuana cards and doctors in ${locationString}`
        : "Find medical marijuana cards and doctors near me",
  };
};

export const getPageMetaData = ({
  countryCode = "US",
  location,
  locationOverrides = {},
  organization,
  query = {},
  retailType = "dispensary",
}: {
  countryCode?: string;
  location?: Location;
  locationOverrides?: FAQ;
  organization?: Organization;
  query?: QueryParams;
  retailType?: RetailType;
}) => {
  if (retailType === "dispensary") {
    return dispensariesMetaData(
      countryCode,
      location,
      query,
      locationOverrides,
      organization,
    );
  }
  if (retailType === "cbd-store") {
    return cbdStoresMetaData(locationOverrides, location);
  }
  return MMJDoctorsMetaData(locationOverrides, location);
};
