import { MouseEvent, useContext, useEffect } from "react";

import { Action, Category } from "constants/events";
import ConfigContext from "context/ConfigContext";
import FinderResultsContext from "context/FinderResultsContext";
import UIContext from "context/UIContext";
import { Store } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { isDeliveryOnly } from "utils/dispensaryUtils";
import { getDistanceCustomRounding, getRoundedDistance } from "utils/distance";
import { getMerchId } from "utils/eventTracking";
import { getStoreUrl } from "utils/storeUrlUtils";

import StarRating from "components/botanic/StarRating";
import ClearIcon from "components/Icons/x.svg";
import Image from "components/Image";
import OfferLabel from "components/OfferLabel";

type Props = {
  store: Store;
  isAd: boolean;
  finderMigrationFlag?: boolean;
};

const Spotlight = ({ store, isAd, finderMigrationFlag }: Props) => {
  const { publishEvent } = useEventTracker();
  const { deals, distanceMi, name, mostRecentActiveDeal } = store;
  const { setSpotlightDismissed } = useContext(UIContext);

  const { storeRoute } = useContext(ConfigContext);
  const countryCode = useDomainCountryCode();

  const { strainFilters, availableFilters } = useContext(FinderResultsContext);

  let distance = null;
  if (distanceMi) {
    distance = getDistanceCustomRounding(distanceMi, countryCode);
  }

  const deliveryOnly = isDeliveryOnly(store);

  // find the most recently edited deal
  const mostRecentDeal = finderMigrationFlag
    ? mostRecentActiveDeal
      ? mostRecentActiveDeal
      : undefined
    : deals && deals.length
      ? deals.reduce((a, b) => (a.lastModified > b.lastModified ? a : b))
      : undefined;

  const {
    active: dealActive,
    discountLabel,
    externalKind,
    title: dealTitle,
  } = mostRecentDeal || {};
  const showDeal = dealActive && discountLabel;
  const dealIsOffer = ["bogo", "bundle"].includes(externalKind || "");

  useEffect(() => {
    if (isAd) {
      // Map Spotlight impressions and clicks and custom map marker/platinum listing clicks are critical company metrics
      publishEvent({
        action: Action.impression,
        category: Category.mapSpotlight,
        dispensaryDistance: getRoundedDistance(store.distanceMi, countryCode),
        dispensaryId: store.id,
        isKeyMetric: true,
        label: name,
        merchandisingCampaignId: getMerchId(store, "marquee"),
        nonInteractionEvent: true,
      });
    }
  }, []);

  const handleClick = () => {
    // Map Spotlight impressions and clicks and custom map marker/platinum listing clicks are critical company metrics
    publishEvent({
      action: Action.click,
      category: Category.mapSpotlight,
      dispensaryId: store.id,
      isKeyMetric: true,
      label: name,
      merchandisingCampaignId: getMerchId(store, "marquee"),
    });
  };

  const storeURL = isAd
    ? `${storeRoute}${store.slug}`
    : getStoreUrl({
        availableFilters,
        storeRoute: storeRoute,
        storeSlug: store.slug,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
        strainFilters: strainFilters as any,
      });

  const handleClose = (e: MouseEvent) => {
    e.preventDefault();
    setSpotlightDismissed(true);
    // Map Spotlight impressions and clicks and custom map marker/platinum listing clicks are critical company metrics
    publishEvent({
      action: Action.click,
      category: Category.mapSpotlight,
      label: "close",
    });
  };

  return (
    <div
      className="bg-white shadow-low flex rounded m-4 md:m-0 relative p-md md:max-w-[224px]"
      data-testid="spotlight-store"
    >
      {isAd && (
        <button
          className="absolute left-3 top-3 bg-default opacity-60 rounded-full p-0.5 -mt-1 -ml-1 z-10"
          onClick={handleClose}
          title={`Click to close this ad`}
        >
          <ClearIcon
            className="text-white"
            width="20"
            height="20"
            style={{ minWidth: "20px" }}
          />
        </button>
      )}
      <a
        className="w-full flex flex-wrap justify-between md:justify-start md:block"
        href={storeURL}
        onClick={handleClick}
      >
        <Image
          className="mx-auto h-[60px] w-[60px] md:h-[120px] md:w-[120px]"
          src={
            finderMigrationFlag ? store.logoUrl || "" : store.logoImage || ""
          }
          sizes={[60, null, 120, null, null, 120]}
          alt={`Spotlight: ${store.name}`}
        />
        <div className="flex flex-wrap w-3/4 md:w-full md:pt-md">
          <div className="grid w-5/6">
            <div className="text-xs underline truncate max-w-[95%] font-bold">
              {store.name}
            </div>
            <StarRating
              rating={store.starRating}
              ratingCount={
                finderMigrationFlag ? store.reviewCount : store.numberOfReviews
              }
              starWidth={13}
            />
            {distance && !deliveryOnly && (
              <div className="flex-shrink-0 text-xs">{distance} away</div>
            )}
          </div>
          {isAd && (
            <div className="w-1/6 flex flex-1 justify-end items-start">
              <span className="text-xs px-xs bg-leafly-white text-grey rounded">
                ad
              </span>
            </div>
          )}
          {showDeal && mostRecentDeal && (
            <div className="w-5/6 md:w-full pt-1">
              {dealIsOffer ? (
                <OfferLabel label={mostRecentDeal.title} />
              ) : (
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <div
                      className="bg-notification rounded text-white font-bold mr-sm text-xs"
                      style={{ padding: "2px 4px" }}
                    >
                      {discountLabel}
                    </div>
                  </div>
                  <span
                    className="font-bold text-xs text-notification"
                    style={{ paddingTop: "2px" }}
                  >
                    {dealTitle}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </a>
    </div>
  );
};

export default Spotlight;
