import { useContext } from "react";

import ConfigContext from "context/ConfigContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";

import FinderLinkButton from "components/Finder/FinderLinkButton";

export const FinderLinks = () => {
  const countryCode = useDomainCountryCode();
  const { retailType } = useContext(ConfigContext);

  const onDoctors = retailType === "clinic";
  const onCBD = retailType === "cbd-store";
  const onDispensaries = !onDoctors && !onCBD;

  const doctorLink = "/medical-marijuana-doctors";
  const cbdLink = "/cbd-stores";
  const dispensariesLink = "/dispensaries";
  const dispensariesSubCopy = `Find licensed medical marijuana doctors${
    countryCode === "CA"
      ? " or clinics nearby"
      : ", clinics, or CBD-only stores nearby."
  }`;
  const cbdSubCopy =
    "Find legal dispensaries or licensed medical marijuana doctors and clinics nearby.";
  const doctorsSubCopy = `Find legal dispensaries${
    countryCode === "CA" ? "" : " or CBD-only stores nearby"
  }.`;

  return (
    <div
      className="container py-lg"
      data-testid="finder-links"
      id="finder-links"
      role="complementary"
    >
      <h2 data-testid="finder-links-section-header" className="mb-sm">
        Looking for something else?
      </h2>
      <p className="text-sm">
        {onDispensaries && dispensariesSubCopy}
        {onCBD && cbdSubCopy}
        {onDoctors && doctorsSubCopy}
      </p>

      <div className="py-md lg:py-lg flex flex-row">
        {!onDispensaries && (
          <FinderLinkButton
            header="Dispensaries"
            link={dispensariesLink}
            trackingInfo={{ category: "Link", label: "Dispensaries" }}
            className="flex-1 mr-sm shadow-low"
          />
        )}
        {!onDoctors && (
          <FinderLinkButton
            header="Doctors &amp; clinics"
            link={doctorLink}
            trackingInfo={{ category: "Link", label: "Doctors & clinics" }}
            className="flex-1 mr-sm shadow-low"
          />
        )}
        {!onCBD && countryCode !== "CA" && (
          <FinderLinkButton
            header="CBD stores"
            link={cbdLink}
            trackingInfo={{ category: "Link", label: "CBD stores" }}
            className="flex-1 mr-sm shadow-low"
          />
        )}
      </div>
    </div>
  );
};

export default FinderLinks;
