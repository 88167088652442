import classnames from "classnames";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  children: any;
  onClick?: () => void;
  className: string;
  isActive?: boolean;
  finderSitemapLink?: boolean;
};

const FinderButton = ({
  children,
  onClick,
  className,
  isActive,
  finderSitemapLink,
  ...props
}: Props) => {
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "enter" && onClick) {
      onClick();
    }
  };
  return (
    <button
      onClick={onClick}
      onKeyPress={handleKeyPress}
      className={classnames(
        "flex flex-row border bg-white rounded ".concat(className || ""),
        {
          "border-light-green": isActive,
          "border-light-grey": !isActive,
          "items-center": !finderSitemapLink,
        },
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default FinderButton;
