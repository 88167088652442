import React, { Fragment } from "react";

import { CopyBlock as CopyBlockType } from "custom-types/Directus";

const CopyBlock = ({
  data,
  className,
}: {
  data: CopyBlockType;
  className?: string;
}) => (
  <div className={className}>
    {data?.copy_block?.map((block, idx) =>
      block.header && block.copy ? (
        <Fragment key={`${block.header}-${idx}`}>
          <h2 className="text-xs font-bold uppercase mb-sm">{block.header}</h2>
          <div
            className="text-xs mb-lg"
            dangerouslySetInnerHTML={{ __html: block.copy }}
          />
        </Fragment>
      ) : (
        <></>
      ),
    )}
  </div>
);

export default CopyBlock;
