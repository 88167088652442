import Link from "next/link";

import FeedbackSurvey from "components/FeedbackSurvey";
import { AboutLeafly } from "components/Footer/AboutLeafly";
import { AppStoreButtons } from "components/Footer/AppStoreButtons";
import { BusinessSolutions } from "components/Footer/BusinessSolutions";
import { Copyright } from "components/Footer/Copyright";
import { DispensaryQuickLinks } from "components/Footer/DispensaryQuickLinks";
import { PrivacyAndTerms } from "components/Footer/PrivacyAndTerms";
import SocialIconLinks from "components/Footer/SocialIconLinks";
import LegalLogo from "components/Icons/legal-logo.svg";

function Footer() {
  return (
    <footer className="bg-leafly-white border-t border-light-grey flex flex-col">
      <FeedbackSurvey surveyId="jAU3zvrF" />
      <Link
        className="text-green mx-auto my-md"
        aria-label="Navigate to home page"
        href="/"
      >
        <LegalLogo width="84" height="33" />
      </Link>
      <hr className="border-light-grey" />
      <div className="flex py-xl justify-center">
        <SocialIconLinks />
      </div>
      <hr className="border-light-grey" />
      <section className="px-lg">
        <BusinessSolutions isResponsive={false} />
      </section>
      <hr className="border-light-grey" />
      <section className="px-lg">
        <AboutLeafly isResponsive={false} />
      </section>
      <hr className="border-light-grey" />
      <section className="px-lg">
        <DispensaryQuickLinks isResponsive={false} />
      </section>
      <hr className="border-light-grey" />
      <section className="px-lg">
        <PrivacyAndTerms isResponsive={false} />
      </section>
      <hr className="border-light-grey mb-xl" />
      <AppStoreButtons />
      <hr className="border-light-grey mt-xl" />
      <section className="pb-24 px-lg">
        <Copyright />
      </section>
    </footer>
  );
}

export default Footer;
