import { forwardRef, MouseEventHandler, useEffect, useState } from "react";

import { Store } from "custom-types/Store";
import useWindowSize from "hooks/useWindowSize";
import { hasLeaflyDelivery, hasLeaflyPickup } from "utils/dispensaryUtils";
import { getTierName } from "utils/tiers";

import PickupReadinessDispensaryCard from "components/PickupReadinessDispensaryCard";

type Props = {
  compact?: boolean;
  hideOnMobile?: boolean;
  href: string;
  id: number | string;
  platinumDeals: string;
  store: Store;
  selected: boolean;
  className: string;
  retailType?: string;
  isUnorderedList?: boolean;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  ctaOnClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  finderMigrationFlag?: boolean;
};

const StoreCard = forwardRef(
  (
    {
      compact,
      hideOnMobile,
      href,
      id,
      platinumDeals,
      store,
      selected,
      className,
      retailType,
      isUnorderedList,
      finderMigrationFlag,
      ...handlers
    }: Props,
    ref,
  ) => {
    const [viewedAsSelected, setViewedAsSelected] = useState(false);

    const isMobile = useWindowSize().isMobile;

    // on_p1 for only tier:0 dispensaries
    // on_plat for other dispensaries (will still only show for platinum)
    const showDeal =
      (platinumDeals === "on_p1" && store.tier === 0) ||
      platinumDeals === "on_plat";

    let conditionalProps = {};

    if (store.distanceMi) {
      conditionalProps = {
        distanceMi: store.distanceMi,
      };
    }
    if (compact || store.retailType === "clinic") {
      // Should we update FEC component to accept a boolean for `hideCTA`?
      conditionalProps = { ...conditionalProps, hideCTA: "true" };
    }

    useEffect(() => {
      if (selected && !viewedAsSelected && !isMobile && document) {
        setViewedAsSelected(true);
        return document
          .getElementById(id.toString())
          ?.scrollIntoView({ block: "center" });
      }
      return () => {};
    });

    const tierName = getTierName(store);
    const Tag = isUnorderedList ? "li" : "div";

    return (
      <Tag
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
        ref={ref as any}
        data-testid={id.toString()}
        className="h-full new-store-card-component"
        id={id.toString()}
        onMouseEnter={handlers.onMouseEnter}
        onMouseLeave={handlers.onMouseLeave}
      >
        <PickupReadinessDispensaryCard
          compact={!!compact}
          hideOnMobile={hideOnMobile}
          currentStatus={store.currentStatuses?.store}
          deal={
            showDeal
              ? finderMigrationFlag
                ? store.mostRecentActiveDeal
                : store.deals?.[0]
              : undefined
          }
          showDeal={showDeal}
          deliveryEnabled={
            (!!store.reservableMenuItemCount ||
              store.flags.includes("delivery")) &&
            hasLeaflyDelivery(store)
          }
          dispensaryId={store.id}
          featureTier={compact ? undefined : store.tier}
          ctaHref={href}
          inStoreCartEnabled={store.inStoreCartEnabled}
          isAd={tierName === "platinum"}
          isNewDispensary={store.isNewDispensary}
          logoUrl={store?.logoImage || store?.logoUrl || ""}
          name={store.name}
          photoUrl={store.coverPhotoUrl}
          pickupEnabled={
            !!store.reservableMenuItemCount && hasLeaflyPickup(store)
          }
          preorderConfigured={store.preorderConfigured}
          rating={store.starRating}
          reviewCount={store.numberOfReviews}
          scheduleType="store"
          slug={store.slug}
          tags={store.flags}
          timeZone={store.timeZone || undefined}
          variant={compact ? "standard" : undefined}
          retailType={retailType}
          className={className}
          preserveHeight={false}
          useLegacyFulfillmentText
          onClick={handlers.onClick}
          ctaOnClick={handlers.ctaOnClick}
          {...conditionalProps}
        />
      </Tag>
    );
  },
);

export default StoreCard;
