import React from "react";
import classNames from "classnames";

import { FAQ as FAQType } from "custom-types/Directus";

import Faq from "./FAQ";

const FAQs: React.FC<{
  className?: string;
  data: FAQType["faqs"];
  initialShowPanel?: boolean;
  onEntryClick?: () => void;
  title?: string;
}> = ({
  className,
  data,
  initialShowPanel = false,
  onEntryClick,
  title = "Frequently asked questions",
}) =>
  data?.length ? (
    <section className={classNames("container", className)}>
      <h2 className="mb-sm">{title}</h2>
      {data.map((q) =>
        q.question && q.answer ? (
          <Faq
            question={q.question}
            answer={q.answer}
            initialShowPanel={initialShowPanel}
            key={q.question}
            onClick={onEntryClick}
          />
        ) : (
          <></>
        ),
      )}
    </section>
  ) : (
    <></>
  );

export default FAQs;
