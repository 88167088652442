export const isWindowReady = (): boolean =>
  typeof window !== "undefined" &&
  window.document &&
  window.document.readyState === "complete";

export default isWindowReady;

const CA_COUNTRY_CODE = "CA";
const US_COUNTRY_CODE = "US";
const US_AND_TERRITORY_CODES = [US_COUNTRY_CODE, "AS", "GU", "MP", "PR", "VI"];

type ShouldNoIndexProps = {
  isLocationPage: boolean;
  stateCode?: string | undefined;
  domainCountryCode: string;
  locationCountryCode: string;
};

const locationIsUSOrTerritories = (
  locationCountryCode: string,
  stateCode?: string,
) => {
  /*
    Google Maps Geocoding API ommits the countryCode field in it's response, It only ommits it (from what we found)
    for Arizona, the state.
 */
  if (!locationCountryCode) {
    return stateCode === "AZ";
  }
  return US_AND_TERRITORY_CODES.includes(locationCountryCode);
};

export const shouldNoIndexLocationPages = ({
  isLocationPage,
  stateCode,
  domainCountryCode,
  locationCountryCode,
}: ShouldNoIndexProps): boolean => {
  return (
    isLocationPage &&
    // should not add noindex if .com domain is accessed from US or US territories
    !(
      (domainCountryCode === US_COUNTRY_CODE &&
        locationIsUSOrTerritories(locationCountryCode, stateCode)) ||
      // should not add noindex if .ca domain is accessed from CA
      (domainCountryCode === CA_COUNTRY_CODE &&
        locationCountryCode === CA_COUNTRY_CODE)
    )
  );
};
