import { createContext, useState } from "react";

const noop = () => {};

interface UIContextProps {
  filterModalOpen: boolean;
  setFilterModalOpen: (filterModalOpen: boolean) => void;
  setSpotlightDismissed: (spotlightDismissed: boolean) => void;
  spotlightDismissed: boolean;
}

export const uiContext = {
  filterModalOpen: false,
  setFilterModalOpen: noop,
  setSpotlightDismissed: noop,
  spotlightDismissed: false,
};

const UIContext = createContext<UIContextProps>(uiContext);

UIContext.displayName = "FinderUIContext";

export const FinderUIContextConsumer = UIContext.Consumer;

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [spotlightDismissed, setSpotlightDismissed] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  return (
    <UIContext.Provider
      value={{
        filterModalOpen,
        setFilterModalOpen,
        setSpotlightDismissed,
        spotlightDismissed,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIContext;
