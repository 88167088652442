import classnames from "classnames";

import MapIcon from "components/Icons/map.svg";

import styles from "./ListingsWrapper.module.css";

type Props = {
  showMapButton: boolean;
  onClick: () => void;
};

export default function SwitchToMapButton({ showMapButton, onClick }: Props) {
  return (
    <div
      className={classnames(
        styles.hideButton,
        styles.mobileListingTransition,
        "md:hidden text-xs bg-white px-md py-sm fixed bottom-0 left-0 right-0 shadow-low z-10 ",
        showMapButton && styles.showButton,
      )}
    >
      <button
        onClick={onClick}
        className="w-full text-green p-sm rounded border border-light-grey flex justify-center items-center"
      >
        <MapIcon width="16" height="16" className="mr-sm" />
        switch to map view
      </button>
    </div>
  );
}
