import { useContext } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

import FinderResultsContext from "context/FinderResultsContext";
import UIContext from "context/UIContext";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { REQUEST_LOCATION } from "redux/reducers/location";
import isCanada from "utils/isCanada";

import Button from "components/botanic/Button";
import TrackImpression from "components/TrackImpression";

type PrimaryCtaProps = {
  title: string;
  description: string;
  buttonText: string;
  onClick?: () => void;
  link?: string;
};

const PrimaryCta = ({
  title,
  description,
  buttonText,
  onClick = () => {},
  link,
}: PrimaryCtaProps) => (
  <div className="similar-strains-cta text-center mb-md mt-md">
    <p className="text-center font-bold">{title}</p>
    <p className="text-sm mb-lg">{description}</p>
    <Button href={link} variant="secondary" size="full" onClick={onClick}>
      {buttonText}
    </Button>
  </div>
);

type SecondaryCtaProps = {
  isCa: boolean;
  isStrainPage: boolean;
};

const SecondaryCta = ({ isCa, isStrainPage }: SecondaryCtaProps) => {
  const dispatch = useDispatch();

  const openLocationChanger = () => dispatch({ type: REQUEST_LOCATION });

  const { setFilterModalOpen } = useContext(UIContext);

  const { availableFilters, activeFilters } = useContext(FinderResultsContext);

  const handleSetFiltersOpenKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "enter") {
      setFilterModalOpen(true);
    }
  };

  const handleLocationKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "enter") {
      openLocationChanger();
    }
  };

  const hasAvailableFilters = !!availableFilters?.length;
  const hasActiveFilters = !!activeFilters?.length;
  const showCbdLink = isStrainPage && !isCa;
  return (
    <>
      <p className="text-sm font-bold">
        Some other suggestions to improve your results:
      </p>
      <ul className="list-disc list-inside text-green">
        <li>
          <button
            className="font-bold"
            onClick={openLocationChanger}
            onKeyPress={handleLocationKeyPress}
          >
            Change your location
          </button>
        </li>
        {showCbdLink && (
          <li className="mt-sm font-bold">
            <Link href="/cbd-stores">Search for CBD Dispensaries</Link>
          </li>
        )}
        {hasAvailableFilters && (
          <li>
            <button
              className="mt-sm font-bold"
              onClick={() => setFilterModalOpen(true)}
              onKeyPress={handleSetFiltersOpenKeyPress}
            >
              Refine your filters
            </button>
          </li>
        )}
        {!hasAvailableFilters && hasActiveFilters && (
          <li>
            <button
              className="mt-sm font-bold"
              onClick={() => setFilterModalOpen(true)}
              onKeyPress={handleSetFiltersOpenKeyPress}
            >
              Remove your last filter or clear all
            </button>
          </li>
        )}
      </ul>
    </>
  );
};

type buildCtaContentProps = {
  pathname: string;
  strain?: string;
  isCa: boolean;
  activeFilters: string[];
};

const buildCtaContent = ({
  pathname,
  strain,
  isCa,
  activeFilters,
}: buildCtaContentProps) => {
  const onDoctors = pathname.includes("doctors");
  const onCBD = pathname.includes("cbd-stores");
  const hasStrainFilter = !!strain;
  const onDispensaries = !onDoctors && !onCBD && !hasStrainFilter;
  const onStrain = !onDoctors && !onCBD && hasStrainFilter;
  const onSJEI = activeFilters.includes("black_owned");

  const handleSimilarStrainsClick = () => {
    const similarStrainsContainer = document.getElementById(
      "similar-strains-container",
    );
    if (!similarStrainsContainer) {
      return;
    }

    const offSet = -96;
    const scrollLocation =
      similarStrainsContainer.getBoundingClientRect().top +
      window.pageYOffset +
      offSet;
    window.scrollTo({ behavior: "smooth", top: scrollLocation });
  };

  if (onDispensaries && isCa && !onSJEI) {
    return {
      link: "/medical-marijuana-doctors",
      primaryButtonText: "find doctors nearby",
      primaryDescription:
        "We don't see any legal dispensaries nearby, but there might be an MMJ doctor who can help.",
      primaryTitle: "Find a medical marijuana doctor instead?",
      title: "No stores nearby",
    };
  } else if (onStrain) {
    return {
      onClick: handleSimilarStrainsClick,
      primaryButtonText: "similar strains",
      primaryDescription:
        "We couldn't find this strain within 150 miles of your location. But science tells us there are similar strains you might like. Yeah, science!",
      primaryTitle: "Find the same vibe",
      title: `No dispensaries with ${strain} nearby`,
    };
  } else if (onDoctors) {
    return {
      link: "/dispensaries",
      primaryButtonText: "find dispensary nearby",
      primaryDescription:
        "We don't see any MMJ doctors nearby, but there might be a cannabis dispensary that can help.",
      primaryTitle: "Try a dispensary instead?",
      title: "No MMJ doctors nearby",
    };
  } else if (onCBD) {
    return {
      link: "/dispensaries",
      primaryButtonText: "find dispensaries nearby",
      primaryDescription:
        "We don't see any CBD stores near you, but there might be a cannabis dispensary that can help.",
      primaryTitle: "Look for dispensaries instead?",
      title: "No CBD stores nearby",
    };
  } else if (onSJEI) {
    return {
      link: "/social-impact",
      primaryButtonText: "Leafly | Social impact",
      primaryDescription:
        "The cannabis industry has a problem with equity. Learn what we're doing, and how you can help.",
      primaryTitle: "We don't see any BIPOC-owned stores near you.",
      title: "No dispensaries nearby",
    };
  }
};

const NoResults = () => {
  const { pathname } = useRouter();
  const {
    strainFilters,
    activeFilters,
    finderLocation,
    savedRadius,
    storesError,
  } = useContext(FinderResultsContext);

  const { query } = useRouter();

  const countryCode = useDomainCountryCode();
  const isCa = isCanada(countryCode);
  const isDelivery = query?.filter?.includes("delivery");
  const hasStrainSelected = !!strainFilters?.strain;

  const ctaContent = buildCtaContent({
    activeFilters,
    isCa,
    pathname,
    strain: strainFilters?.strain || undefined,
  });

  const showPrimaryCta = !hasStrainSelected || hasStrainSelected;

  const { city, state, zip } = finderLocation;

  const customDimensions = {
    errorMessage: storesError ? "Request error" : undefined,
    searchCity: city,
    searchRadius: savedRadius,
    searchState: state,
    searchZip: zip,
  };

  const NoDeliveryResultsIcon = dynamic<{
    height: string;
    width: string;
  }>(() => import("components/Icons/no_delivery_results.svg"));

  if (isDelivery) {
    return (
      <TrackImpression
        category="Finder delivery results"
        customDimensions={customDimensions}
        label="No locations nearby"
        className="finder__no-results"
        data-testid="finder-no-results--delivery"
      >
        <div className="flex flex-col items-center large-y-margin">
          <NoDeliveryResultsIcon width="112" height="115" />
          <div className="font-bold">You're outside this delivery zone.</div>
          <div className="text-center">
            Zones are expanding all the time, keep checking back! Until then,
            try ordering for in-store pickup.
          </div>
        </div>
      </TrackImpression>
    );
  }

  if (!ctaContent) {
    return null;
  }

  return (
    <TrackImpression
      category="Finder results"
      label="No locations nearby"
      className="finder__no-results mt-sm"
      data-testid="finder-no-results"
      customDimensions={customDimensions}
    >
      <p className="font-extrabold text-md">{ctaContent.title}</p>
      {showPrimaryCta && (
        <PrimaryCta
          title={ctaContent.primaryTitle}
          description={ctaContent.primaryDescription}
          buttonText={ctaContent.primaryButtonText}
          onClick={ctaContent.onClick}
          link={ctaContent.link}
        />
      )}
      <SecondaryCta isCa={isCa} isStrainPage={!!strainFilters?.strain} />
    </TrackImpression>
  );
};

export default NoResults;
