import { useContext } from "react";

import ConfigContext from "context/ConfigContext";

import FAQs from "components/FAQ";

const CitiesFAQs = () => {
  const { FAQData } = useContext(ConfigContext);

  return FAQData.data.faqs?.length ? (
    <div className="px-lg bg-green-20 py-xxl">
      <FAQs data={FAQData.data.faqs} initialShowPanel={true} />
    </div>
  ) : (
    <></>
  );
};

export default CitiesFAQs;
