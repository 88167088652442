import { useContext } from "react";
import { trackEvent } from "@leafly-com/web-utils";

import MapContext from "context/MapContext";

const ZoomControls = () => {
  const { setZoomLevel, zoomLevel, setShowSearchButton } =
    useContext(MapContext);

  const handleZoomIn = () => {
    trackEvent("Map", "Zoom In");

    setZoomLevel(Math.min(zoomLevel + 1, 20));
    setShowSearchButton(true);
  };

  const handleZoomOut = () => {
    trackEvent("Map", "Zoom Out");

    setZoomLevel(Math.max(zoomLevel - 1, 1));
    setShowSearchButton(true);
  };
  const classes =
    "h-10 w-10 leading-none border border-light-grey text-green text-xxl";
  return (
    <div className="bg-white flex-col font-bold hidden text-lg lg:flex my-xl shadow-low pointer-events-auto">
      <button onClick={handleZoomIn} className={classes}>
        &#43;
      </button>
      <button onClick={handleZoomOut} className={classes}>
        &minus;
      </button>
    </div>
  );
};

export default ZoomControls;
