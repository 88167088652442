import { useContext, useRef } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
/* eslint-disable-next-line no-restricted-imports -- FIXME: legacy implementation; replace with Headless UI Transition */
import { CSSTransition } from "react-transition-group";

import MapContext from "context/MapContext";
import UIContext from "context/UIContext";
import { ViewEnum, ViewType } from "custom-types/Finder";
import useWindowSize from "hooks/useWindowSize";
import { getLocationState } from "redux/selectors/location";
import { hasFullAddress } from "utils/hasFullAddress";
import slugifyName from "utils/slugifyName";

import StoreCard from "components/Finder/FinderListings/StoreCard";
import Geolocator from "components/Finder/FinderMap/MapOverlays/Geolocator";
import SearchButton from "components/Finder/FinderMap/MapOverlays/SearchButton";
import ZoomControls from "components/Finder/FinderMap/MapOverlays/ZoomControls";

import Spotlight from "./Spotlight";

const MapOverlays: React.FC<{
  listingPosition: ViewType;
  finderMigrationFlag?: boolean;
}> = ({ listingPosition, finderMigrationFlag }) => {
  const { isMobile } = useWindowSize();
  const { spotlightDismissed } = useContext(UIContext);
  const userLocation = useSelector(getLocationState);

  const { selectedStore, showSearchButton, spotlight, loadingMapMarkers } =
    useContext(MapContext);

  const { query } = useRouter();

  const hasOrganizationId = !!query.organization_id;

  const isDeliveryTab = !!query.filter?.includes("delivery");
  const hasDeliveryAddressSet = isDeliveryTab && hasFullAddress(userLocation);
  const hideSearchArea = hasOrganizationId || hasDeliveryAddressSet;

  const spotlightRef = useRef<HTMLDivElement>(null);
  const storeRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {loadingMapMarkers && listingPosition === ViewEnum.MAP && (
        <div className="absolute flex justify-center top-0 left-0 right-0">
          <SearchButton isLoading>Loading markers</SearchButton>
        </div>
      )}
      {!hideSearchArea &&
        showSearchButton &&
        listingPosition !== ViewEnum.LIST && (
          <div className="absolute flex justify-center top-0 left-0 right-0">
            <SearchButton>Search this area</SearchButton>
          </div>
        )}

      <div className="absolute right-0 top-0 m-md flex flex-col">
        <Geolocator />
        <ZoomControls />
      </div>

      <div className="absolute bottom-0 right-0 left-0 md:w-fit">
        <CSSTransition
          classNames="transition-card"
          in={
            isMobile
              ? !spotlightDismissed && !selectedStore
              : !spotlightDismissed
          }
          nodeRef={spotlightRef}
          timeout={500}
          unmountOnExit
        >
          <div ref={spotlightRef} className="md:m-lg">
            {spotlight && (
              <Spotlight
                store={spotlight}
                isAd
                finderMigrationFlag={finderMigrationFlag}
              />
            )}
          </div>
        </CSSTransition>
      </div>
      <div className="absolute bottom-0 right-0 left-0 md:hidden">
        <CSSTransition
          in={!!selectedStore}
          nodeRef={storeRef}
          timeout={500}
          classNames="transition-card"
          unmountOnExit
        >
          <div ref={storeRef} className="m-lg">
            {selectedStore && (
              <StoreCard
                store={selectedStore}
                compact
                hideOnMobile={true}
                id={`${slugifyName(
                  selectedStore.name,
                )}-mobile-overlay-store-card`}
                index={0}
              />
            )}
          </div>
        </CSSTransition>
      </div>
      <style jsx>{`
        .transition-card-enter {
          transform: translateY(100%);
          opacity: 0;
        }
        .transition-card-enter-active {
          opacity: 1;
          transform: translateY(0);
          transition:
            opacity 300ms,
            transform 300ms;
        }
        .transition-card-exit {
          opacity: 1;
          transform: translateY(0);
        }
        .transition-card-exit-active {
          opacity: 0;
          transform: translateY(100%);
          transition:
            opacity 300ms,
            transform 300ms;
        }
      `}</style>
    </>
  );
};

export default MapOverlays;
