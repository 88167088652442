import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

import ConfigContext from "context/ConfigContext";
import LocationContext from "context/LocationContext";
import { RetailType } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getFilteredOverride } from "utils/seoMetaDataStrings";

import { DeliveryValidation } from "../DeliveryValidation";

const Hero = () => {
  const { finderLocation } = useContext(LocationContext);

  const { state, city, formattedLocation } = finderLocation;
  const location = city ? formattedLocation : state;
  const {
    FAQData,
    isLocationPage,
    organization: { name },
    retailType,
  } = useContext(ConfigContext);
  const h1Override = FAQData?.data?.h1_override;
  const { query } = useRouter();
  const includesDeliveryFilter = query.filter?.includes("delivery");
  const includesPickupFilter = query.filter?.includes("pickup");
  const includesSingleFilter =
    query?.filter &&
    !Array.isArray(query.filter) &&
    ["black_owned", "open_now", "medical"].includes(query.filter);

  const tab =
    (includesDeliveryFilter && "delivery") ||
    (includesPickupFilter && "pickup") ||
    "all";

  const [showDeliveryValidation, setShowDeliveryValidation] = useState<
    boolean | undefined
  >(false);

  const countryCode = useDomainCountryCode();
  const canadaSafeWord = countryCode === "CA" ? "stores" : "dispensaries";

  const tabHeader = {
    all: "Shop all",
    delivery: "Delivery",
    pickup: "Pickup",
  };

  const tabHeaderMessage = () => {
    return tab === "all"
      ? `Find weed dispensaries near you in ${location}`
      : `${tabHeader[tab]} ${canadaSafeWord} in ${location}`;
  };

  const singleLocationHeaderMessage = () => {
    const overrideText = getFilteredOverride(query.filter as string);
    return isLocationPage
      ? `${location} ${overrideText.toLowerCase()}`
      : overrideText;
  };

  const dispensaryHeaderMessage = () => {
    if (name) {
      return `All ${name} dispensary locations`;
    }
    if (includesSingleFilter) {
      return singleLocationHeaderMessage();
    }
    return tabHeaderMessage();
  };

  const headerMessage = (retailType: RetailType) => {
    const locationString = isLocationPage ? `in ${location}` : "near you";
    switch (retailType) {
      case "cbd-store":
        return `${tabHeader[tab]} Hemp / CBD stores ${locationString}`;
      case "clinic":
        return `Find MMJ doctors & cards ${locationString}`;
      default:
        return dispensaryHeaderMessage();
    }
  };

  useEffect(() => {
    setShowDeliveryValidation(includesDeliveryFilter);
  }, [query]);

  return (
    <div>
      <div className="py-md width-full">
        <h1
          id="finder-listings__header-message"
          className="heading--s font-bold"
          data-testid="header-message"
        >
          {h1Override ?? headerMessage(retailType)}
        </h1>
        {showDeliveryValidation && <DeliveryValidation />}
      </div>
    </div>
  );
};

export default Hero;
