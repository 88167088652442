import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function useTrackImpression(
  trackImpression: () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  options: any,
) {
  const [ref, inView, entry] = useInView({
    threshold: 1,
    triggerOnce: true,
    ...options,
  });

  useEffect(() => {
    if (inView) {
      trackImpression();
    }
  }, [inView]);

  return [ref, inView, entry];
}
