import { CSSProperties, useContext } from "react";
import classnames from "classnames";

import FinderResultsContext from "context/FinderResultsContext";
import UIContext from "context/UIContext";

import FinderButton from "components/Finder/FinderButton";
import FilterIcon from "components/Icons/filter.svg";

type Props = {
  className?: string;
  style?: CSSProperties;
};

const FilterModalButton: React.FC<Props> = ({ className, ...props }) => {
  const { setFilterModalOpen } = useContext(UIContext);
  const { activeFilters } = useContext(FinderResultsContext);

  return (
    <FinderButton
      onClick={() => setFilterModalOpen(true)}
      className={classnames(
        "text-green font-bold px-sm m-xs text-xs w-auto",
        className,
      )}
      data-testid="filter-modal-button"
      {...props}
    >
      <span className="pr-xs">
        <FilterIcon width="12" height="11" className="text-green" />
      </span>
      Filters
      {activeFilters.length > 0 && (
        <span className="px-xs" data-testid="number-of-filters">
          ({activeFilters.length})
        </span>
      )}
    </FinderButton>
  );
};

export default FilterModalButton;
