import { ReactElement } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import classnames from "classnames";
import Link from "next/link";

import TrackImpression from "components/TrackImpression";

import FinderButton from "../FinderButton";

type Props = {
  link: string;
  header: string;
  content: ReactElement;
  className: string;
  btnClasses: string;
  trackingInfo: {
    category: string;
    label: string;
  };
  finderSitemapLink?: boolean;
};

export const FinderLinkButton = ({
  link,
  header,
  content,
  className,
  btnClasses,
  trackingInfo,
  finderSitemapLink,
}: Props) => (
  <Link
    href={link}
    className={className}
    onClick={() => {
      trackEvent(trackingInfo.category, "Click", trackingInfo.label);
    }}
    data-testid={`${header}-button-link`}
  >
    <TrackImpression
      category={trackingInfo.category}
      label={trackingInfo.label}
      className="w-full h-full"
    >
      <FinderButton
        finderSitemapLink={finderSitemapLink}
        className={classnames(
          "justify-between p-md w-full ".concat(btnClasses),
          { "h-full": finderSitemapLink },
        )}
      >
        <div className="text-xs flex flex-col text-left">
          <span className="text-green font-bold">{header}</span>
          <span>{content}</span>
        </div>
      </FinderButton>
    </TrackImpression>
  </Link>
);

FinderLinkButton.defaultProps = {
  btnClasses: "",
  content: "Find nearby",
};

export default FinderLinkButton;
