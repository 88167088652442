import { Location } from "custom-types/Location";
import { getCookie } from "utils/cookies";

import { hasFullAddress } from "./hasFullAddress";
import logError from "./logError";

/** Get location info from the 'leafly-location' cookie */
/**
 * @deprecated Please use api/requests/getUserLocation.ts instead
 */
export const getUserLocationInfoFromCookie = (
  httpCookie?: string,
): Location | undefined => {
  const key = "leafly-location";
  const locationCookie = httpCookie
    ? getHttpCookie(httpCookie, key)
    : getCookie(key);

  let userLocation;
  try {
    userLocation =
      locationCookie && JSON.parse(decodeURIComponent(locationCookie));
  } catch {
    logError("Failed to decode location cookie using deprecated utility", {
      functionName: "getUserLocationInfoFromCookie",
    });
  }

  return userLocation ? normalizeLocationCookie(userLocation) : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
type LocationCookie = Record<string, any>;

const getHttpCookieObject = (httpCookie: string): LocationCookie => {
  return httpCookie
    .split(";")
    .map((cookie) => cookie.split("="))
    .reduce((acc, cookie) => {
      const [key, value] = cookie;
      if (key && value) {
        acc[decodeURIComponent(key.trim())] = decodeURIComponent(value.trim());
      }
      return acc;
    }, {} as LocationCookie);
};

export const getHttpCookie = (httpCookie: string, key: string) =>
  getHttpCookieObject(httpCookie)[key];

/** If cookie is set in header, it will be missing important properties
 *  or will be in a different format. This normalizes the properties,
 *  but probably not a great long term solution.
 */
export const normalizeLocationCookie = (
  cookie?: LocationCookie,
): Location | undefined => {
  if (!cookie) {
    return;
  }
  if (cookie.postalCode && !cookie.zip) {
    cookie.zip = cookie.postalCode;
  }

  cookie.formattedAddress = getFormattedAddressFromCookie(cookie);

  if (cookie.zip && !cookie.postalCode) {
    cookie.postalCode = cookie.zip;
  }

  if ((cookie.coordinates && !cookie.latitude) || !cookie.longitude) {
    cookie.latitude = cookie.coordinates.latitude;
    cookie.longitude = cookie.coordinates.longitude;
  }

  if (cookie.country_code) {
    cookie.countryCode = cookie.country_code;
  }

  return cookie;
};

/** Format address from cookie into a readable string to use in the
 *  DeliveryValidationModal and Button
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const getFormattedAddressFromCookie = (location: any) => {
  if (!location || !hasFullAddress(location)) {
    return "";
  }
  return `${location.street.number} ${location.street.name}, ${location.city}, ${location.state_code}, ${location.zip}, ${location.country_code}`;
};
