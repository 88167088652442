export const filters = {
  allBtn: "apply-filters-button-all",
  dealsBtn: "apply-filters-button-deals",
  deliveryBtn: "apply-filters-button-delivery",
  medicalBtn: "apply-filters-button-medical",
  modal: {
    clear: "filters-modal-footer-clear",
    footer: "filters-modal-footer",
    submit: "filters-modal-footer-apply",
  },
  open: "filter-modal-button",
  openNowBtn: "apply-filters-button-open-now",
  option: "filter-checkbox-label",
  pickupBtn: "apply-filters-button-pickup",
  preOrderBtn: "apply-filters-button-pre-order",
  recreationalBtn: "apply-filters-button-recreational",
  remove: "active-filter-button",
  removeLabel: "active-filter-label",
  subFilter: "subfilter",
  submit: "apply-filters-button",
};

export const sort = {
  dropdown: "sort-dropdown",
  option: "sort-option",
};

export const views = {
  desktopList: "desktop-list",
  desktopMap: "desktop-map",
  mobileList: "mobile-list",
  mobileMap: "mobile-map",
};

export default {
  filters,
  sort,
  views,
};
