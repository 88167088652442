import React, { useContext } from "react";

import ConfigContext from "context/ConfigContext";

import Divider from "components/StrainPage/Divider";

import CopyBlock from "./CopyBlock";

type Props = {
  className?: string;
};

const CopyBlockWithData = ({ className }: Props) => {
  const { copyBlocks } = useContext(ConfigContext);
  if (!copyBlocks) {
    return null;
  }

  return copyBlocks?.copy_block?.length ? (
    <div className="copy-block-section">
      <Divider />
      <div className="copy-block-container px-lg">
        <CopyBlock data={copyBlocks} className={className} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CopyBlockWithData;
