import { useEffect, useRef, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import { useDispatch, useSelector } from "react-redux";

import { Location } from "custom-types/Location";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { updateLocation } from "redux/action-creators/location";
import { getReloadPageAfterLocationChange } from "redux/selectors/header";
import { getLocationState } from "redux/selectors/location";
import getCurrentGeolocationPosition from "utils/getCurrentGeolocationPosition";
import { getUserLocationInfoFromCookie } from "utils/getUserLocationFromCookie";
import { hasFullAddress } from "utils/hasFullAddress";
import {
  formatAddress,
  getLocationForCookie,
  getLocationSlug,
  setLocationCookie,
} from "utils/locationUtils";

import DeliveryValidationModal from "components/botanic/DeliveryValidationModal";
import DeliveryValidationButton from "components/botanic/DeliveryValidationModal/DeliveryValidationButton";

const DeliveryValidation = () => {
  const userLocation = useSelector(getLocationState);
  const shouldRefreshLocation = useSelector(getReloadPageAfterLocationChange);

  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  /**
   * By the time a user reaches this line, they will have a location loaded in the cookie.
   * See: https://github.com/Leafly-com/web-web/blob/551dfb6b1b92171fd245bcca6d3f7a5c06c8d8e4/pages/_app.tsx#L276
   *
   * Ideally, this value should instead be read out of the redux store, and not directly
   * from the cookie itself.
   */
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see comment above
  const locationCookie = getUserLocationInfoFromCookie()!;
  const countryCode = useDomainCountryCode();
  useEffect(() => {
    if (hasFullAddress(locationCookie)) {
      if (
        !userLocation.formattedAddress ||
        userLocation.formattedAddress.includes("undefined")
      ) {
        // add formattedAddress for DeliveryValidationModal and update cookie value for formattedAddress
        const addressWithFormattedAddress = {
          ...userLocation,
          formattedAddress: formatAddress(userLocation),
        };

        setLocationCookie({
          countryCode,
          isUserLocation: addressWithFormattedAddress.isUserLocation,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
          location: addressWithFormattedAddress as any,
        });
        return setDeliveryLocation(addressWithFormattedAddress);
      }
      setDeliveryLocation(userLocation);
    }
  }, [userLocation]);

  const [deliveryLocation, setDeliveryLocation] = useState(locationCookie);

  const handleUpdateDeliveryLocation = async (newAddress: Location) => {
    const location = getLocationForCookie(newAddress, true);
    const newLocation = {
      ...newAddress,
      ...location,
      slug: getLocationSlug(location),
    };
    //store new address in state
    setDeliveryLocation(newLocation);
    updateLocation(dispatch, newLocation);
  };

  const handleSeeDispensaries = (newAddress: Location) => {
    if (newAddress !== userLocation) {
      handleUpdateDeliveryLocation(newAddress);
    }

    trackEvent(
      "Finder - Delivery Address Validation Modal",
      "Click",
      "Confirmed Delivery Address",
    );

    setIsVisible(false);
  };

  const dispatch = useDispatch();
  const handleSecondaryAction = () => {
    trackEvent(
      "Finder - Delivery Validation Geolocate",
      "Detect Delivery Area Location",
    );

    return getCurrentGeolocationPosition(
      dispatch,
      (location: Location) => {
        setDeliveryLocation(location);
        updateLocation(dispatch, location);
        setIsVisible(false);
      },
      { reloadPageOnSuccess: shouldRefreshLocation },
    );
  };

  const handleClick = () => {
    trackEvent("Delivery", "Click", "Address validation: Finder");
    setIsVisible(true);
  };

  return (
    <>
      <div className="pb-xs pt-lg" data-testid="delivery-validation-btn">
        <DeliveryValidationButton
          address={deliveryLocation?.formattedAddress}
          handleClick={handleClick}
          ref={buttonRef}
        />
      </div>
      <DeliveryValidationModal
        data-testid="delivery-validation-modal"
        defaultAddress={deliveryLocation}
        handlePrimaryAction={handleSeeDispensaries}
        handleSecondaryAction={handleSecondaryAction}
        onDismissed={() => setIsVisible(false)}
        primaryActionLabel="see dispensaries"
        returnFocusRef={buttonRef}
        secondaryActionLabel="use current location"
        showModal={isVisible}
        successMessage=""
        width={500}
      />
    </>
  );
};

export default DeliveryValidation;
