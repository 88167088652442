import { AvailableSort } from "custom-types/AvailableFilters";

// TODO: These should be exported from finder-service-client
export default [
  {
    display: "Recommended",
    param: "default",
  },
  {
    display: "Online Ordering",
    param: "online-ordering",
  },
  {
    display: "Distance",
    param: "distance",
  },
  {
    display: "Highest rated",
    param: "highest-rated",
  },
  {
    display: "Most reviews",
    param: "most-reviews",
  },
  {
    display: "A - Z",
    param: "a-z",
  },
  {
    display: "Z - A",
    param: "z-a",
  },
] as AvailableSort[];
