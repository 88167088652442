import { ReactNode, useContext, useState } from "react";
import classnames from "classnames";
import throttle from "lodash/throttle";
import dynamic from "next/dynamic";
import { useSwipeable } from "react-swipeable";

import ConfigContext from "context/ConfigContext";
import UIContext from "context/UIContext";
import { ViewEnum, ViewType } from "custom-types/Finder";

import CopyBlockWithData from "components/CopyBlock/index";
import CitiesFAQsWithData from "components/Finder/CitiesFAQs";
import CitiesNearby from "components/Finder/CitiesNearby/CitiesNearby";
import ActiveFilters from "components/Finder/Filters/ActiveFilters";
import FilterModalButton from "components/Finder/Filters/FilterModalButton";
import FilterPillButtons from "components/Finder/Filters/FilterPillButtons";
import FiltersAsTabs from "components/Finder/Filters/FiltersAsTabs";
import FinderBreadcrumbs from "components/Finder/FinderBreadcrumbs";
import FinderLinks from "components/Finder/FinderLinks";
import FinderListings from "components/Finder/FinderListings";
import Footer from "components/Finder/Footer";
import Hero from "components/Finder/Hero";

import SwitchToMapButton from "./SwitchToMapButton";
import styles from "./ListingsWrapper.module.css";

const MobileFilters = dynamic(
  () => import("components/Finder/Filters/MobileFilters"),
);

const SWIPE_THRESHOLD = 20;

type SectionWrapperProps = {
  extraClasses?: string;
  id?: string;
  children: ReactNode;
};

const SectionWrapper = ({
  extraClasses,
  children,
  ...others
}: SectionWrapperProps) => (
  <div className={`px-lg ${extraClasses || ""}`} {...others}>
    {children}
  </div>
);

const ListingsWrapper: React.FC<{
  listingPosition: ViewType;
  setListingPosition: (position: ViewType) => void;
  trackMobileViewClickEvent: (listingPosition: ViewType) => void;
  finderSiteMapFlag?: boolean;
  finderMigrationFlag?: boolean;
}> = ({
  listingPosition,
  setListingPosition,
  trackMobileViewClickEvent,
  finderSiteMapFlag,
  finderMigrationFlag,
}) => {
  const [dragPosition, setDragPosition] = useState(null);
  const [dragging, setDragging] = useState(false);

  const { filterModalOpen } = useContext(UIContext);

  const { retailType } = useContext(ConfigContext);

  const handleSwipeUp = () => {
    if (listingPosition !== ViewEnum.LIST) {
      setListingPosition(ViewEnum.LIST);
      trackMobileViewClickEvent(ViewEnum.LIST);
    }
  };

  const handleSwipeDown = () => {
    if (listingPosition !== ViewEnum.MAP) {
      setListingPosition(ViewEnum.MAP);
      trackMobileViewClickEvent(ViewEnum.MAP);
    }
  };

  const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    if (e.pointerType === "mouse") {
      const position =
        listingPosition === ViewEnum.LIST ? ViewEnum.HALF : ViewEnum.LIST;
      setListingPosition(position);
      trackMobileViewClickEvent(position);
    }
    return;
  };

  const handleTap = () => {
    if (listingPosition === ViewEnum.HALF) {
      setListingPosition(ViewEnum.LIST);
      trackMobileViewClickEvent(ViewEnum.LIST);
    } else {
      setListingPosition(ViewEnum.HALF);
    }
  };

  const throttledDrag = throttle((e) => {
    setDragPosition(e.initial[1] + e.deltaY);
  }, 50);

  const listSwipeHandlers = useSwipeable({
    onSwipeStart: () => {
      setDragging(true);
    },
    onSwiped: () => {
      setDragging(false);
      setDragPosition(null);
    },
    onSwipedDown: (e) => {
      if (e.absY > SWIPE_THRESHOLD) {
        handleSwipeDown();
      }
    },
    onSwipedUp: (e) => {
      if (e.absY > SWIPE_THRESHOLD) {
        handleSwipeUp();
      }
    },
    onSwiping: (e) => {
      throttledDrag(e);
    },
    onTap: () => {
      handleTap();
    },
    preventScrollOnSwipe: true,
  });

  const handleSwitchToMap = () => {
    setListingPosition(ViewEnum.MAP);
  };

  const product = {
    "cbd-store": "Hemp / CBD",
    clinic: "MMJ Doctors",
    dispensary: "Weed",
    undefined: "Weed",
  };

  const useFiltersAsTabs = retailType !== "clinic";

  return (
    <>
      <div
        id="listing-wrapper"
        className={classnames(
          "shadow-low",
          styles.listingWrapper,
          listingPosition === ViewEnum.LIST && styles.fullHeightListing,
          !dragging && styles.mobileListingTransition,
          listingPosition === ViewEnum.HALF && styles.halfHeightListing,
          listingPosition === ViewEnum.MAP && styles.lowHeightListing,
        )}
        style={{
          transform: dragPosition
            ? `translateY(${dragPosition}px) translateZ(0px)`
            : undefined,
        }}
        onTouchEnd={handleSwipeUp}
      >
        <div
          className={styles.listingWrapperHandle}
          {...listSwipeHandlers}
          // Prevents handle touches from propagating to the wrapper handler
          onPointerDown={handlePointerDown}
          onTouchEnd={(e) => e.stopPropagation()}
          data-testid="listing-wrapper-handle"
        >
          <span className={styles.listingWrapperHandleSpan} />
        </div>

        <div
          className={classnames("h-full md:overflow-y-auto", {
            "overflow-y-auto": listingPosition === ViewEnum.LIST,
          })}
        >
          {useFiltersAsTabs && (
            <SectionWrapper extraClasses="finder__filters-as-tabs md:pt-lg">
              <FiltersAsTabs />
            </SectionWrapper>
          )}

          <SectionWrapper extraClasses="finder__hero">
            <Hero />
          </SectionWrapper>

          <hr className="mb-sm border-light-grey" />
          <div className="finder__filters px-md">
            <ActiveFilters className="w-full md:flex-wrap overflow-x-auto overflow-y-hidden">
              <FilterModalButton
                className="px-md py-xs"
                style={{ flex: "0 0 auto" }}
              />
              <FilterPillButtons />
            </ActiveFilters>
          </div>

          <SectionWrapper extraClasses="finder__listings mb-xl">
            <FinderListings finderMigrationFlag={finderMigrationFlag} />
          </SectionWrapper>

          <SectionWrapper extraClasses="finder__links bg-leafly-white my-xl py-xxl">
            <FinderLinks />
          </SectionWrapper>
          <SectionWrapper extraClasses="finder__cities-nearby">
            <CitiesNearby
              heading={`Find ${
                product[retailType as keyof typeof product]
              } in a city near you`}
              finderSiteMapFlag={finderSiteMapFlag}
            />
          </SectionWrapper>
          <CopyBlockWithData className="mt-xxl mb-10" />
          <CitiesFAQsWithData />
          <SectionWrapper
            extraClasses="finder__breadcrumbs my-xxl"
            id="finder-listings-breadcrumbs"
          >
            <FinderBreadcrumbs />
          </SectionWrapper>

          <Footer />
          <div
            id="padding-for-map-toggle"
            className="block md:hidden"
            style={{ height: "52px" }}
          />
        </div>
      </div>

      <SwitchToMapButton
        showMapButton={listingPosition === ViewEnum.LIST}
        onClick={handleSwitchToMap}
      />
      {filterModalOpen && <MobileFilters />}
    </>
  );
};

export default ListingsWrapper;
